<template>
  <b-modal id="GrantInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/grants.svg" class="icon-lg" />
        {{ $t("Grants.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.grantNameAr"
        :title="$t('Grants.nameAr')"
        :imgName="'grants.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.grantNameEn"
        :title="$t('Grants.nameEn')"
        :imgName="'grants.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.grantNameUnd"
        :title="$t('Grants.nameUnd')"
        :imgName="'grants.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.mathematicalTypeNameAr"
        :title="$t('Grants.mathematicalTypeNameAr')"
        :imgName="'grants.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.mathematicalTypeNameEn"
        :title="$t('Grants.mathematicalTypeNameEn')"
        :imgName="'grants.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.mathematicalTypeNameUnd"
        :title="$t('Grants.mathematicalTypeNameUnd')"
        :imgName="'grants.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.grantDescriptionAr"
        :title="$t('Grants.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.grantDescriptionEn"
        :title="$t('Grants.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="grant.grantDescriptionUnd"
        :title="$t('Grants.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-12'"
        :value="grant.grantNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {};
  },
  props: ["grant"],
  methods: {},
};
</script>
